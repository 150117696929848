import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MenuLayout } from '../layouts';
import { setLanguage } from '../store/actions/config';
import { api } from '../services';

const MenuPage = props => {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [premiumData, setPremiumData] = useState({});
  const [premiumHasExpired, setPremiumHasExpired] = useState(false);
  const [errorPremiumMessage, setErrorPremiumMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const currentLang = useSelector(({ config }) => config.language);
  const menuStore = useSelector(store => store.menu);
  const userStore = useSelector(store => store.login);
  const { fetchMenuData } = api();

  const changeLang = lang => {
    dispatch(setLanguage(lang));
  };

  useEffect(() => {
    let ignore = false;
    /**
     * Esta funcion es similar a todas las que obtienen datos, salvo la excepcion
     * de que si hay una excepcion con un mensaje de 'Unauthorized'
     * Esta configura las opciones para mostrar un modal con el correspondiente msj
     * de que la cuenta expiro.
     * @param {Boolean} ignore
     */
    const fetchData = async ignore => {
      try {
        if (!ignore) {
          const menuResponse = await fetchMenuData(currentLang);
          setData(menuResponse);
        }
      } catch (e) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData(ignore);
    return () => {
      ignore = true;
    };
  }, [currentLang, fetchMenuData]);

  return (
    <MenuLayout
      premiumData={premiumData}
      data={{ ...data, menuStore, currentLang }}
      errorPremiumMessage={errorPremiumMessage}
      premiumHasExpired={premiumHasExpired}
      loading={loading}
      error={error}
      onChangeLanguage={changeLang}
      prevPath={props.location.state.prevPath || ''}
    />
  );
};

export default MenuPage;
